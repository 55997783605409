import React from 'react';
import { BrowserRouter } from 'react-router-dom';

function ReactRouter({ children }) {
  return (
    <BrowserRouter
      getUserConfirmation={() => {
        /* empty callback prevents native behaviour */
      }}
    >
      {children}
    </BrowserRouter>
  );
}

export default ReactRouter;
