export default {
  // style object for base or default style
  baseStyle: {
    minWidth: 75,
    marginLeft: '15px',
    borderRadius: 3,
    textTransform: 'none',
    fontSize: '1.2rem',
    padding: '8px 15px 5px 15px',
    fontFamily: 'font.black',

    'svg': {
      transform: 'translateY(-2px)',
    }
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    lg: {
      height: '36px',
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: {
      background: 'primary.main',
      color: '#000',
      _active: {
        border: `1px solid primary.main`,
      },
      _hover: {
        background: 'primary.button.hover',
        border: `1px solid primary.button.hover`,
        color: '#000',
      },
      _disabled: {
        background: 'primary.button.disabled',
        border: `1px solid primary.button.disabled`,
        color: '#000 !important',
        '& > span.MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
    secondary: {
      // backgroundColor: 'white.main',
      fontFamily: 'font.medium',
      border: '1px solid',
      color: 'white.main',
      _disabled: {
        opacity: 0.5,
        '& > span.MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
    secondaryDark: {
      backgroundColor: 'white.main',
      fontFamily: 'font.medium',
      border: '1px solid #000',
      color: '#000',
      _disabled: {
        opacity: 0.5,
        '& > span.MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
    tertiary: {
      fontFamily: 'font.medium',
      background: 'pink.light',
      color: '#000',
      '&:not(:hover):not(:disabled)': {
        border: `1px solid pink.light`,
      },
    },
  },

  // default values for `size` and `variant`
  defaultProps: {
    size: '',
    variant: '',
  },
};
