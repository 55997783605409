import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  getValidTokenAndRedirectIfInvalid,
  invalidateSessionAndForceLogin,
} from '../../../helpers/authHelpers/authenticationHelpers';

function AuthenticatedRoute(props) {
  const { component: Child, ...rest } = props;
  const token = getValidTokenAndRedirectIfInvalid();

  if (token == null) {
    invalidateSessionAndForceLogin();
    return null;
  }

  return <Route {...rest} render={(componentProps) => <Child {...componentProps} />} />;
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
};

export default AuthenticatedRoute;
