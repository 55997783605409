import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';

import { tenantId, clientId, b2cEndpoint, b2cPolicy, redirectUri } from '../../config';

export const MICROSOFT_USER_JWT_LOCAL_STORAGE_KEY = 'msToken';
export const AUTHENTICATION_NONCE_LOCAL_STORAGE_KEY = 'auth_nonce';

export const getMSTokenFromLocalStorage = () =>
  window.localStorage.getItem(MICROSOFT_USER_JWT_LOCAL_STORAGE_KEY);

export const setMSTokenInLocalStorage = (token) => {
  window.localStorage.setItem(MICROSOFT_USER_JWT_LOCAL_STORAGE_KEY, token);
};

export const removeMSTokenFromLocalStorage = () => {
  window.localStorage.removeItem(MICROSOFT_USER_JWT_LOCAL_STORAGE_KEY);
};

export const createNonce = () => {
  const nonce = uuidv4();
  window.localStorage.setItem(AUTHENTICATION_NONCE_LOCAL_STORAGE_KEY, nonce);
  return nonce;
};

export const isValidLoginRedirectNonce = (nonce) => {
  const localStorageNonce = window.localStorage.getItem(AUTHENTICATION_NONCE_LOCAL_STORAGE_KEY);
  return nonce != null && localStorageNonce === nonce;
};

export const createRedirectState = (obj = {}) => {
  const nonce = createNonce();
  const queryString = window.location.search;
  const pathName = window.location.pathname;

  const redirectTo =
    pathName === '/logged-in' || pathName === '/log-in' ? '/' : pathName + queryString;
  const state = btoa(
    JSON.stringify({
      nonce,
      redirectTo,
      ...obj,
    }),
  );
  return state;
};

const navigateToUrl = (url) => {
  window.location.replace(url);
};

export const redirectUserToLoginPage = (stateObject = {}) => {
  const state = createRedirectState(stateObject);
  const url =
    process.env.NODE_ENV === 'development'
      ? `${window.location.origin}/dev-log-in`
      : `https://${b2cEndpoint}/${tenantId}/oauth2/v2.0/authorize?p=${b2cPolicy}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${redirectUri}&scope=openid%20${clientId}&response_type=token&prompt=login&state=${state}&response_mode=form_post`;

  navigateToUrl(url);
};

export const redirectUserToLogOutPage = () => {
  const url = `${window.location.origin}/logged-out`;
  navigateToUrl(url);
};

export const invalidateSessionAndForceLogin = async (stateObject = {}) => {
  await removeMSTokenFromLocalStorage();

  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });

  redirectUserToLoginPage(stateObject);
};

export const logOutUser = () => {
  removeMSTokenFromLocalStorage();

  redirectUserToLogOutPage();
};

export const getValidTokenAndRedirectIfInvalid = () => {
  const token = getMSTokenFromLocalStorage();
  const decodedJwt = jwt.decode(token);

  if (
    token == null ||
    decodedJwt == null ||
    !decodedJwt ||
    decodedJwt?.originalToken?.payload?.exp == null
  ) {
    invalidateSessionAndForceLogin();
    return null;
  }

  const exp = decodedJwt?.originalToken?.payload?.exp;

  const expires = new Date(exp * 1000);
  if (expires.getTime() <= Date.now()) {
    invalidateSessionAndForceLogin();
    return null;
  }

  return token;
};
