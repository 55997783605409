const noOutline = {
  bg: 'grey.light',
  borderRadius: '3px',
  border: '0px solid',
  resize: 'none',
  color: 'white',
  _placeholder: {
    color: 'white',
  },
  _invalid: {
    border: '1px solid',
    borderColor: 'red',
  },
};

const mandatory = {
  bg: 'grey.light',
  borderRadius: '3px',
  border: '1px solid',
  borderColor: 'primary.main',
  resize: 'none',
  color: 'white',
  _placeholder: {
    color: 'white',
  },
  _invalid: {
    border: '1px solid',
    borderColor: 'red',
  },
};

const light = {
  bg: 'transparent',
  borderRadius: '3px',
  border: '1px solid',
  borderColor: 'grey.d8d8d8',
  resize: 'none',
  color: 'black',
  _placeholder: {
    color: 'black',
  },
  _invalid: {
    border: '1px solid',
    borderColor: 'red',
  },
}

const md = {
  fontSize: 'size12',
  lineHeight: '15px',
  fontWeight: 'medium',
  fontFamily: 'medium',
  height: '94px',
};

const TextArea = {
  variants: { noOutline, mandatory, light },
  sizes: { md },
  defaultProps: {
    sizes: 'md',
    variant: 'noOutline',
  },
};

export default TextArea
