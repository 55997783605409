import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './loadingSpinner.styles';

function LoadingSpinner({ ...rest }) {
  const classes = styles();
  return (
    <div className={classes.loadingSpinner} data-testid="loading-spinner">
      <CircularProgress {...rest} />
    </div>
  );
}

export default LoadingSpinner;
