import React from 'react';
import AppConfiguration from './appConfiguration';

import '@fontsource/spartan/100.css';
import '@fontsource/spartan/200.css';
import '@fontsource/spartan/300.css';
import '@fontsource/spartan/400.css';
import '@fontsource/spartan/500.css';
import '@fontsource/spartan/600.css';
import '@fontsource/spartan/700.css';
import '@fontsource/spartan/800.css';
import '@fontsource/spartan/900.css';

import Routes from './routes/Routes';

function App() {
  return (
    <AppConfiguration>
      <Routes />
    </AppConfiguration>
  );
}

export default App;
