import React from 'react';
import jwt from 'jsonwebtoken';
import { getMSTokenFromLocalStorage } from '../../../helpers/authHelpers/authenticationHelpers';

import { UserContext, userReducer, initialUserState } from './userContext/userContext';
import { ParamsContext, paramsReducer, initialParamsState } from './paramsContext/paramsContext';

const AppContext = ({ children }) => {
  const [userState, userDispatch] = React.useReducer(userReducer, initialUserState);
  const [paramsState, paramsDispatch] = React.useReducer(paramsReducer, initialParamsState);

  React.useEffect(() => {
    const microsoftToken = getMSTokenFromLocalStorage();
    const decodedJwt = jwt.decode(microsoftToken);

    if (decodedJwt != null) {
      const { id, firstName, surname, roles, scopes } = decodedJwt.userInfo;
      const emailAddress = decodedJwt.originalToken.payload.emails[0];
      userDispatch({
        type: 'SET_LOGGED_IN_USER',
        payload: { id, email: emailAddress, firstName, surname, roles, scopes },
      });
    }
  }, []);

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      <ParamsContext.Provider value={{ paramsState, paramsDispatch }}>
        {children}
      </ParamsContext.Provider>
    </UserContext.Provider>
  );
};

export default AppContext;
