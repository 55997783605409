export default {
  baseStyle: {
    track: {
      bg: 'grey.light',
      _checked: {
        bg: 'primary.main',
      },
      _focus: {
        boxShadow: 'none',
        border: '0px solid',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
