/* eslint-disable import/prefer-default-export */
import { getEnvironmentFromUrl } from './helpers';

const baseUrl = process?.env?.REACT_APP_LOCAL_BASE_URL || `https://${window.location.host}`;

const environment = getEnvironmentFromUrl();

const hapAPIURL = process?.env?.REACT_APP_LOCAL_API_URL || `${baseUrl}/api`;
const redirectUri = `${baseUrl}/api/auth/login`;

const clientId = process?.env[`REACT_APP_${environment}_CLIENT_ID`];
const tenantId = process?.env[`REACT_APP_${environment}_TENANT_ID`];
const b2cEndpoint = process?.env[`REACT_APP_${environment}_B2C_ENDPOINT`];
const b2cPolicy = process?.env?.REACT_APP_B2C_POLICY;

export { clientId, tenantId, b2cPolicy, b2cEndpoint, hapAPIURL, redirectUri };
