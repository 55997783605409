const greyLightest = '#494947'; // e.g. progress line connector bg
const greyLighter = '#474745'; // e.g. profile progress checkmark incomplete
const greyMidLight = '#444444'; // checkbox color, email verified bg
const greyLight = '#414141'; // e.g. menu sidebar bg
const greyMid = '#3B3B39'; // e.g. profile progress section bg
const greyDark = '#3A3A3A'; // e.g. profile form bg
const greyDarker = '#333331'; // e.g. profile page bg
const greyDarkest = '#2F2F2F'; // e.g. select options bg
const white = '#FFFFFF';
const dark = '#000000';
const greyFaint = '#4D4D4D';
const greyFainter = '#4C4C4C';
const primary = '#53D8D8';
const pink = '#FF3C6A';
const pinkLight = '#F4B0AE';
const primaryButtonHover = '#50C8C8';
const primaryButtonDisabled = '#438584';
const d8d8d8 = '#D8D8D8'

export default {
  grey: {
    darkest: greyDarkest,
    darker: greyDarker,
    dark: greyDark,
    mid: greyMid,
    midlight: greyMidLight,
    light: greyLight,
    faint: greyFaint,
    fainter: greyFainter,
    lighter: greyLighter,
    lightest: greyLightest,
    d8d8d8: d8d8d8
  },
  white: {
    main: white,
  },
  dark: {
    main: dark,
  },
  primary: {
    main: primary,
    button: {
      hover: primaryButtonHover,
      disabled: primaryButtonDisabled,
    },
  },
  pink: {
    main: pink,
    light: pinkLight,
  },
  secondary: {
    main: white,
  },
  error: {
    main: pink,
  },
};
