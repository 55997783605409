export default {
  baseStyle: {
    control: {
      bg: 'grey.light',
      borderRadius: '3px',
      _disabled: {
        bg: 'grey.light',
        color: 'black.100',
        borderColor: 'black.100',
      },
      _hover: {
        bg: 'grey.light',
        _checked: {
          bg: 'white',
          color: 'white',
          border: '0px solid',
        },
      },
      _checked: {
        bg: 'white',
        color: 'white',
        border: '0px solid',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  variants: {
    modal: {
      border: '1px solid #D8D8D8',
      control: {
        color: 'black',
        bg: 'white',
        border: '1px solid',
        borderColor: '#D8D8D8',
        borderRadius: '3px',
        _disabled: {
          bg: 'transparent',
          color: 'black.100',
        },
        _hover: {
          bg: '#D8D8D8',
          _checked: {
            bg: '#52D8D8',
            color: 'black',
          },
          _disabled: {
            bg: 'gray.200',
          },
        },
        _checked: {
          bg: '#52D8D8',
          color: 'black',
          border: '0px solid',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  sizes: {
    sm: {
      control: {
        w: '16px',
        h: '16px',
        mr: '8px',
      },
      label: {
        fontSize: 'xs',
        lineHeight: '15px',
      },
    },
    md: {
      control: {
        w: '19px',
        h: '19px',
        mr: '10px',
      },
      label: {
        fontSize: 'sm',
        lineHeight: '19.5px',
      },
    },
    lg: {
      control: {
        w: '24px',
        h: '23px',
        mr: '10px',
      },
      label: {
        fontSize: 'm',
        lineHeight: '19.5px',
      },
      icon: {
        color: 'black',
        fontSize: 'sm',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
