export default {
  baseStyle: {
    root: {},
  },
  variants: {
    forCard: {
      borderColor: '#4F4F4F',
      borderStyle: 'solid',
      borderBottomWidth: '2px',
    },
  },
  defaultProps: {},
};
