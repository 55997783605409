// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

import {
  FONT_BLACK,
  FONT_BOLD,
  FONT_EXTRA_BOLD,
  FONT_EXTRA_LIGHT,
  FONT_LIGHT,
  FONT_MEDIUM,
  FONT_REGULAR,
  FONT_SEMI_BOLD,
  FONT_THIN,
} from './fonts';

const greyLightest = '#494947'; // e.g. progress line connector bg
const greyLighter = '#474745'; // e.g. profile progress checkmark incomplete
const greyMidLight = '#444444'; // checkbox color, email verified bg
const greyLight = '#414141'; // e.g. menu sidebar bg
const greyMid = '#3B3B39'; // e.g. profile progress section bg
const greyDark = '#3A3A3A'; // e.g. profile form bg
const greyDarker = '#333331'; // e.g. profile page bg
const greyDarkest = '#2F2F2F'; // e.g. select options bg
const white = '#FFFFFF';
const dark = '#000000';
const greyFaint = '#4D4D4D';
const greyFainter = '#4C4C4C';
const primary = '#53D8D8';
const pink = '#FF3C6A';
const pinkLight = '#F4B0AE';
const primaryButtonHover = '#50C8C8';
const primaryButtonDisabled = '#438584';

const appTheme = createTheme({
  palette: {
    grey: {
      darkest: greyDarkest,
      darker: greyDarker,
      dark: greyDark,
      mid: greyMid,
      light: greyLight,
      midlight: greyMidLight,
      faint: greyFaint,
      fainter: greyFainter,
      lighter: greyLighter,
      lightest: greyLightest,
    },
    white: {
      main: white,
    },
    dark: {
      main: dark,
    },
    primary: {
      main: primary,
      button: {
        hover: primaryButtonHover,
        disabled: primaryButtonDisabled,
      },
    },
    pink: {
      main: pink,
      light: pinkLight,
    },
    secondary: {
      main: white,
    },
    error: {
      main: pink,
    },
  },
  typography: {
    fontFamily: 'SpartanRegular, Arial',
    htmlFontSize: 10,
    font: {
      thin: FONT_THIN,
      light: FONT_LIGHT,
      extraLight: FONT_EXTRA_LIGHT,
      regular: FONT_REGULAR,
      medium: FONT_MEDIUM,
      semiBold: FONT_SEMI_BOLD,
      bold: FONT_BOLD,
      extraBold: FONT_EXTRA_BOLD,
      black: FONT_BLACK,
    },
    typescale: {
      h1: '2.1rem',
      h2: '1.2rem',
      h3: '1.4rem',
      h4: '1.2rem',
      h5: '1.1rem',
      h6: '1rem',
      size21: '2.1rem',
      size18: '1.8rem',
      size16: '1.6rem',
      size14: '1.4rem',
      size12: '1.2rem',
      text: '1.2rem',
      subtext: '1.1rem',
      footnote: '1rem',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ':root': {
          fontSize: '62.5%',
        },
        body: {
          fontSize: '1.6rem',
          backgroundColor: greyDarker,
        },
        '#root': {
          maxWidth: 1920,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        ':disabled': {
          cursor: 'not-allowed',
        },
        'h1, h2, h3, h4, h5, h6, p': {
          color: white,
        },
        h1: {
          fontSize: '2.1rem',
          fontFamily: FONT_BOLD,
          lineHeight: '2.4rem',
        },
        h2: {
          fontSize: '1.2rem',
          fontFamily: FONT_SEMI_BOLD,
          lineHeight: '2.1rem',
        },
        h3: {
          fontSize: '1.4rem',
          fontFamily: FONT_SEMI_BOLD,
          lineHeight: '1.7rem',
        },
        h4: {
          fontSize: '1.2rem',
          fontFamily: FONT_MEDIUM,
        },
        h5: {
          fontSize: '1.1rem',
          fontFamily: FONT_MEDIUM,
        },
        h6: {
          fontSize: '1rem',
          fontFamily: FONT_MEDIUM,
        },
        'a, button, figcaption, label, p': {
          fontSize: '1.2rem',
          fontFamily: FONT_REGULAR,
          lineHeight: '1.4rem',
          margin: 0,
        },
        'figcaption, label, p': {
          color: white,
        },
        input: {
          fontSize: '1.2rem',
          fontFamily: FONT_MEDIUM,
        },
        button: {
          border: 0,
          borderRadius: 4,
          background: 'none',
          color: white,
          lineHeight: '1.4rem',
          whiteSpace: 'nowrap',
          textDecoration: 'none',
          padding: 0,
          cursor: 'pointer',
        },
        legend: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        '.MuiPickersCalendar-week p': {
          paddingTop: 3,
          lineHeight: 1,
        },
      },
    },
    MuiPopover: {
      root: {
        zIndex: '9999 !important',
      },
    },
    MuiInputBase: {
      root: {
        color: white,
        border: 'none',
        backgroundColor: '#414141',
        '& > svg': {
          marginRight: 10,
        },
      },
      input: {
        fontSize: '1.2rem',
        fontFamily: FONT_MEDIUM,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
    MuiChip: {
      icon: {
        color: pink,
      },
      colorSecondary: {
        width: '100%',
        backgroundColor: '#414141',
        color: white,
        marginTop: '.5em',
      },
      iconColorSecondary: {
        color: pink,
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        color: greyDarker,
        '& > *': {
          color: 'inherit',
        },
      },
    },
  },
});

export default appTheme;
