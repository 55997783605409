import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import AppContext from './context/AppContext';
import ThemeProvider from './themeProvider/themeProvider';
import ChakraThemeProvider from './chakraTheme/ChakraThemeProvider';
import ReactRouter from './reactRouter/ReactRouter';

const AppConfiguration = ({ children }) => (
  <ReactRouter>
    <AppContext>
      <ThemeProvider>
        <ChakraThemeProvider>
          <CssBaseline>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>{children}</MuiPickersUtilsProvider>
          </CssBaseline>
        </ChakraThemeProvider>
      </ThemeProvider>
    </AppContext>
  </ReactRouter>
);

export default AppConfiguration;
