import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  suspenseLoadingRoot: {
    height: '70vh',
    width: '95vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
