export const FONT_THIN = 'SpartanThin';
export const FONT_LIGHT = 'SpartanLight';
export const FONT_EXTRA_LIGHT = 'SpartanExtraLight';
export const FONT_REGULAR = 'SpartanRegular';
export const FONT_MEDIUM = 'SpartanMedium';
export const FONT_SEMI_BOLD = 'SpartanSemiBold';
export const FONT_BOLD = 'SpartanBold';
export const FONT_EXTRA_BOLD = 'SpartanExtraBold';
export const FONT_BLACK = 'SpartanBlack';

export default {
  fonts: {
    thin: FONT_THIN,
    light: FONT_LIGHT,
    extraLight: FONT_EXTRA_LIGHT,
    regular: FONT_REGULAR,
    medium: FONT_MEDIUM,
    semiBold: FONT_SEMI_BOLD,
    bold: FONT_BOLD,
    extraBold: FONT_EXTRA_BOLD,
    black: FONT_BLACK,

    heading: 'Spartan, Arial, sans-serif',
    body: 'Spartan, Arial, sans-serif',
  },
  fontSizes: {
    h1: '2.1rem',
    h2: '1.2rem',
    h3: '1.4rem',
    h4: '1.2rem',
    h5: '1.1rem',
    h6: '1rem',
    size21: '2.1rem',
    size18: '1.8rem',
    size16: '1.6rem',
    size14: '1.4rem',
    size12: '1.2rem',
    text: '1.2rem',
    subtext: '1.1rem',
    footnote: '1rem',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  globalFonts: {
    h1: {
      fontSize: '2.1rem',
      fontFamily: FONT_BOLD,
      lineHeight: '2.4rem',
    },
    h2: {
      fontSize: '1.2rem',
      fontFamily: FONT_SEMI_BOLD,
      lineHeight: '2.1rem',
    },
    h3: {
      fontSize: '1.4rem',
      fontFamily: FONT_SEMI_BOLD,
      lineHeight: '1.7rem',
    },
    h4: {
      fontSize: '1.2rem',
      fontFamily: FONT_MEDIUM,
    },
    h5: {
      fontSize: '1.1rem',
      fontFamily: FONT_MEDIUM,
    },
    h6: {
      fontSize: '1rem',
      fontFamily: FONT_MEDIUM,
    },
    input: {
      fontSize: '1.2rem',
      fontFamily: FONT_MEDIUM,
    },
  },
};
