export default {
  // The parts of the component
  parts: ['accordion', 'container', 'item', 'panel', 'button'],
  // The base styles for each part
  baseStyle: {
    root: {
      width: '100%'
    },
    container: {
      borderColor: 'grey.light',
      py: '7',
      pr: '4',
    },
    panel: {
      pl: 0,
      pr: 0,
      pt: 4
    },
    button: {
      padding: 0,
      width: 'auto',
    },
    icon: {
      color: 'white'
    }
  },
  // The size styles for each part
  sizes: {},
  // The variant styles for each part
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
}