import React from 'react';

export const UserContext = React.createContext();

export const initialUserState = {
  id: null,
  firstName: '',
  surname: '',
  email: '',
  avatarUrl: null,
  scopes: [],
  roles: [],
};

export const userReducer = (state, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case 'SET_LOGGED_IN_USER':
      return {
        ...nextState,
        id: action.payload.id,
        firstName: action.payload.firstName,
        surname: action.payload.surname,
        email: action.payload.email,
        scopes: action.payload.scopes,
        roles: action.payload.roles,
      };

    case 'UPDATE_USER_AVATAR':
      return {
        ...nextState,
        avatarUrl: action.payload.base64ProfilePicture,
      };

    case 'RESET_LOGGED_IN_USER':
      return { ...initialUserState };

    default:
      return state;
  }
};
