import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../common/errorBoundary/ErrorBoundary';
import LoadingSpinner from '../common/loading/LoadingSpinner';
import AuthenticatedRoute from '../common/authenticatedRoute/AuthenticatedRoute';

import styles from './routes.styles';

export const Login = React.lazy(() => import('../content/login/Login'));
export const Logout = React.lazy(() => import('../content/logout/Logout'));
export const DevLogin = React.lazy(() => import('../content/login/DevLogin'));
export const AuthFailed = React.lazy(() => import('../content/login/AuthFailed'));
export const TermsAndConditions = React.lazy(() =>
  import('../content/termsAndConditions/TermsAndConditions'),
);
export const MainAppComponent = React.lazy(() => import('../content/mainApp/MainAppComponent'));
export const NotFoundPage = React.lazy(() => import('../common/notFoundPage/NotFoundPage'));

// Global Routes here

function Routes() {
  const classes = styles();

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div className={classes.suspenseLoadingRoot}>
            <LoadingSpinner />
          </div>
        }
      >
        <Switch>
          <Route path="/auth-failed" component={AuthFailed} />
          <Route path="/logged-in" component={Login} />
          <Route path="/logged-out" component={Logout} />
          <Route path="/dev-log-in" component={DevLogin} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />

          <AuthenticatedRoute path="/" component={MainAppComponent} />

          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Routes;
