import { extendTheme } from '@chakra-ui/react';
import * as components from './components';
import { colors, typography } from './tokens';
import shadows from './tokens/shadows';

export const chakraTheme = extendTheme({
  styles: {
    global: () => ({
      // html: {
      //   fontFamily: 'Spartan, Arial',
      // },
      body: {
        bg: '',
      },
      'h1, h2, h3, h4, h5, h6, p': {
        color: 'white',
      },
    }),
  },
  colors,
  ...typography,
  shadows,
  components: {
    ...components,
  },
});

export default chakraTheme;
